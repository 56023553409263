<template>
  <main class="main">
    <div class="container">
      <div class="main__inner" v-if="news">
        <BreadcrumbsComponent :title="news.title" v-if="news.title" />
        <section class="section open section-pb48">
          <div class="open__info_row">
            <a class="category" v-if="news.category && news.category.title">
              {{ news.category.title }}
            </a>
            <div class="date" v-if="news.date_publication">
              <time :datetime="news.date_publication | robotDate">
                {{ news.date_publication | humanDateRu }}
              </time>
            </div>
          </div>
          <div class="open__row">
            <div class="open__col-content">
              <div class="open__title" v-if="news.title">
                {{ news.title }}
              </div>
              <div v-if="news.head_img && news.head_img.img && news.head_img.img.url" class="open__img">
                <div class="open__img__content">
                  <img :src="news.head_img | image($store.state.api)" :alt="news.head_img | image_alt" />
                </div>
              </div>
              <EditorJSComponent :text="JSON.parse(news.description)" v-if="news.description" />
              <div v-if="news.join_documents && news.join_documents.length">
                <div class="open__attached_fails">Прикреплённые файлы:</div>
                <div class="open__files">
                  <div class="open__file" :key="index" v-for="(item, index) in news.join_documents">
                    <div class="open__file-main">
                      <img src="/public/images/open/document.png" alt="" />
                      <div class="open__file__info">
                        <a
                          :href="$store.state.api + item.document.url"
                          download
                          target="_blank"
                          v-if="item.document.title"
                          class="open__file__info-title"
                          >{{ item.document.title }}
                        </a>
                        <div class="open__file__info-size">
                          {{ item.document.type }} ({{ item.document.size }})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showActual" class="open__col-aside">
              <ActualNewsComponent />
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>
<script>
import EditorJSComponent from "components/EditorJSComponent.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ActualNewsComponent from "components/ActualNewsComponent.vue";

export default {
  name: "openComponent",
  components: {
    ActualNewsComponent,
    EditorJSComponent,
    BreadcrumbsComponent,
  },
  async asyncData({ store, route }) {
    await store.dispatch("GET_OPEN_NEWS", route.params.id);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.news_open_page = null;
    this.$store.state.news_actual = [];
    next();
  },
  computed: {
    news() {
      return this.$store.state.news_open_page?.news_item;
    },
    showActual() {
      return this.news && this.news.type && this.news.type.code !== this.$store.state.type.CODE_ACTIVITY;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      this.news.meta_title || this.news.title || "Официальный сайт администрации города Махачкалы",
      this.news.meta_keywords || "",
      this.news.meta_description || "",
      this.news.meta_title || this.news.title || "Официальный сайт администрации города Махачкалы",
      this.news.head_img?.img?.url
        ? this.$options.filters.image(this.news.head_img, this.$store.state.api)
        : "",
      this.news.meta_description || ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/parts/news/index.styl"
</style>
